<template>
  <div class="statistique-employeur">
    <div class="body-box-rapport">
      <div class="repartition">
        <div class="title-repartition">
          <div class="title">Année</div>
        </div>
        <div class="ligne-box-setting-header mt-0"></div>

        <div class="body-repartition">
          <div class="menu">
            <div
              @click="Employe.filterYearType = 'annee'"
              class="menu-item"
              :class="{ 'menu-item active': Employe.filterYearType == 'annee' }"
            >
              Par année
            </div>
            <div
              @click="Employe.filterYearType = 'intervalle'"
              class="menu-item "
              :class="{
                'menu-item active': Employe.filterYearType == 'intervalle'
              }"
            >
              Par intervalle d'année
            </div>
          </div>
          <div class="contenue">
            <b-form-select
              v-if="Employe.filterYearType == 'annee'"
              class="b-form-select "
              v-model="Employe.Annee"
              :options="getListYearEmploye"
              @change="handleChange"
            ></b-form-select>
            <template v-if="Employe.filterYearType == 'intervalle'"
              ><b-form-select
                class="b-form-select raduis-left  "
                v-model="Employe.Anne[0]"
                :options="getListYearEmploye"
                @change="handleChange"
              ></b-form-select>
              <b-form-select
                class="b-form-select raduis-rigth "
                v-model="Employe.Anne[1]"
                :options="getListYearEmploye"
                @change="handleChange"
              ></b-form-select
            ></template>
          </div>
        </div>
      </div>
      <div class="repartition">
        <div class="title-repartition">
          <div class="title">Semaine</div>
        </div>
        <div class="ligne-box-setting-header mt-0"></div>
        <div class="body-repartition">
          <div class="menu">
            <div
              @click="Employe.filterSemaineType = 'semaine'"
              class="menu-item"
              :class="{
                'menu-item active': Employe.filterSemaineType == 'semaine'
              }"
            >
              Par Semaine
            </div>
            <div
              @click="Employe.filterSemaineType = 'intervalle'"
              class="menu-item "
              :class="{
                'menu-item active': Employe.filterSemaineType == 'intervalle'
              }"
            >
              Par intervalle de semaine
            </div>
          </div>
          <div class="contenue">
            <SelectComponent
              v-if="Employe.filterSemaineType == 'semaine'"
              :options="ComputedListWeekEmploye"
              :value="Employe.Semaine"
              label="text"
              champName="Semaine"
              filterName="Semaine"
              :track-by="'text'"
              :max-heigh="130"
              placeholder="Rechercher"
              :searchable="true"
              :showLabels="false"
              :change="handleChange"
              classToUse="select-vue-component-style bg-select-vue"
            />
            <template v-if="Employe.filterSemaineType == 'intervalle'">
              <SelectComponent
                :options="ComputedListWeekEmploye"
                :value="Employe.Semaine_min"
                label="text"
                champName="Semaine_min"
                filterName="Semaine_min"
                :track-by="'text'"
                :max-heigh="130"
                placeholder="Rechercher"
                :searchable="true"
                :showLabels="false"
                :change="handleChange"
                classToUse="select-vue-component-style select-vue-component-border-raduis-left bg-select-vue"/>
              <SelectComponent
                :options="ComputedListWeekEmploye"
                :value="Employe.Semaine_max"
                label="text"
                champName="Semaine_max"
                filterName="Semaine_max"
                :change="handleChange"
                :track-by="'text'"
                :max-heigh="130"
                placeholder="Rechercher"
                :searchable="true"
                :showLabels="false"
                classToUse="select-vue-component-style select-vue-component-border-raduis-right bg-select-vue"
            /></template>
          </div>
        </div>
      </div>
      <div class="repartition">
        <div class="title-repartition">
          <div class="title">Type</div>
        </div>
        <div class="ligne-box-setting-header mt-0"></div>
        <div class="body-repartition">
          <div class="contenue">
            <b-form-select
              class="b-form-select"
              v-model="Employe.filterType"
              :options="ComputedgetAllType"
              value-field="value"
              text-field="text"
              @change="handleChange"
            ></b-form-select>
          </div>
        </div>
      </div>
      <div class="repartition">
        <div class="title-repartition">
          <div class="title">Validation salaires</div>
        </div>
        <div class="ligne-box-setting-header mt-0"></div>

        <template>
          <div class="body-repartition">
            <div class="contenue">
              <div class="palatte">
                <div
                  class="palatte-item"
                  v-for="(lab, index) in computedgetStatistuqueSalary"
                  :key="'Employe' + index"
                  @click="
                    onClickEvent({
                      lab: lab,
                      backgroung: datasetEmploye.backgroundColor[index]
                    })
                  "
                  :class="{
                    boxclick: lab.name === Employe.validation
                  }"
                >
                  <div class="name">{{ lab.name | FormatName }}</div>
                  <div
                    class="pourcentage"
                    :style="
                      'background-color:' +
                        datasetEmploye.backgroundColor[index]
                    "
                  >
                    <template v-if="lab.name !== 'Tous'">{{
                      lab.pourcentage
                    }}</template>
                    <template v-if="lab.name === 'Tous'">{{
                      getTotalEmploye[0]
                    }}</template>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
    <div class="table-rapport-style">
      <div class="chart-box-style">
        <Charts
          :label="computedlabelStatistuqueSalary"
          :data="computedDataStatistuqueSalary"
          :datasets="datasetEmploye"
          :width="400"
          :height="400"
        />
      </div>
      <div v-if="seletedValidation" class="pourcentage" :class="ComputedClass">
        <template>{{ seletedValidation.name | FormatName }}</template>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';
export default {
  data() {
    return {
      Employe: {
        validation: 'Tous',
        filterType: null,
        Anne: [],
        Annee: null,
        Semaine_min: null,
        Semaine_max: null,
        Semaine: null,
        ListYear: [],
        ListWeek: [],
        filterYearType: 'annee',
        filterSemaineType: 'semaine'
      },
      datasetEmploye: {
        position: 'right',
        title: '',
        backgroundColor: ['#4F4DAD', '#5880E3', '#fadaa6', '#DD3E3E'],
        hoverBackgroundColor: ['#4F4DAD', '#5880E3', '#fadaa6', '#DD3E3E']
      },
      seletedValidation: { name: 'Tous', background: '#4F4DAD' }
    };
  },
  components: {
    Charts: () => import('../component/StatistiqueComponent.vue'),
    SelectComponent: () => import('../SelectComponent.vue')
  },
  methods: {
    ...mapActions(['employeeSalarysStatistic', 'fetchAllUserAndType']),
    handleChange(payload) {
      if (payload) {
        this.Employe[payload.champName] = payload.value;
      }
      this.employeeSalarysStatistic({
        employe: this.Employe
      });
    },
    onClickEvent({ lab, backgroung }) {
      this.Employe.validation = lab.name;
      this.seletedValidation = { backgroung, ...lab };
      this.employeeSalarysStatistic({
        employe: this.Employe
      });
    }
  },
  computed: {
    ...mapGetters([
      'getAllType',
      'getStatistuqueSalary',
      'getloadingStatistiqueSalary',
      'getTotalEmploye'
    ]),
    getListYearEmploye() {
      return this.Employe.ListYear;
    },
    ComputedListWeekEmploye() {
      return this.Employe.ListWeek;
    },
    ComputedgetAllType() {
      const type = this.getAllType.map(item => ({
        text: item.type,
        value: item.type
      }));
      return [{ text: 'tous', value: null }, ...type];
    },
    computedgetStatistuqueSalary() {
      return this.getStatistuqueSalary;
    },
    computedlabelStatistuqueSalary() {
      if (this.getStatistuqueSalary) {
        return this.getStatistuqueSalary.map(item => {
          return item.name;
        });
      }
      return [];
    },
    computedDataStatistuqueSalary() {
      if (this.getStatistuqueSalary) {
        return this.getStatistuqueSalary.map(item => {
          return item.pourcentage;
        });
      }
      return [];
    },
    ComputedClass() {
      if (
        this.seletedValidation &&
        this.seletedValidation.name &&
        this.seletedValidation.name === 'en attend'
      ) {
        return 'enattend';
      }
      return this.seletedValidation.name;
    }
  },

  filters: {
    FormatName: value => {
      switch (value) {
        case 'valid':
          return 'Validé';
        case 'refuse':
          return 'Refusé';
        case 'encore de paiement':
          return 'En attente  de paiement';
        case 'paye':
          return 'Payé';
        case 'en attend':
          return 'En attente';
        default:
          return value;
      }
    }
  },
  async mounted() {
    var courantYear = moment().year();
    this.Employe.Anne[0] = courantYear;
    this.Employe.Anne[1] = courantYear;
    this.Employe.Annee = courantYear;
    for (var i = 2019; i <= courantYear; i++) {
      this.Employe.ListYear.push({ value: i, text: i });
    }
    let isoWekk = moment().isoWeek();
    this.Employe.Semaine = { value: isoWekk, text: isoWekk };
    this.Employe.Semaine_min = { value: isoWekk, text: isoWekk };
    this.Employe.Semaine_max = { value: isoWekk, text: isoWekk };
    var totalWeekEmploye = moment({
      year: this.Employe.Anne[0]
    }).isoWeeksInYear();
    for (var week = 1; week <= totalWeekEmploye; week++) {
      this.Employe.ListWeek.push({ value: week, text: week });
    }

    this.fetchAllUserAndType();
    await this.employeeSalarysStatistic({
      employe: this.Employe
    });
    this.seletedValidation = {
      name: 'Tous',
      background: '#4F4DAD',
      pourcentage: this.getTotalEmploye[0]
    };
  }
};
</script>
<style scoped lang="scss">
.statistique-employeur {
  display: flex;
  position: relative;
  padding: 7px;
  .body-box-rapport {
    height: calc(100vh - 129px);
    margin: 0px;
    margin-right: 5px;
    width: 45%;
    display: inline;
  }
  .table-rapport-style {
    justify-content: center;
    width: 55%;
    .pourcentage {
      margin: auto;
      margin-top: 1%;
      height: 28px;
      padding: 4px;
      text-align: center;
      width: 85px;
      border-radius: 22px;
      color: #fff;
      box-shadow: 0px 3px 6px #00000038;
    }
    .Tous {
      background-color: #4f4dad;
    }
    .enattend {
      background-color: #5880e3;
    }
    .valid {
      background-color: #fadaa6;
    }
    .refuse {
      background-color: #dd3e3e;
    }
  }
}
</style>
